<template lang="pug">
  div(v-if="this.user.role === 'driver'")
    // Buscador de entregas
    div(class="bg-tab" style="position: sticky; top: 0; z-index: 1;")
      b-input-group.pt-2(
        class="mb-3"
      )
        b-input-group-prepend.ml-2
          b-input-group-text(class="icon-search-input")
            .material-icons.icon(class="icon-search") search
        b-input#search-input-delivery(
          placeholder="Buscar Aqui..."
          v-model="search"
        )
      b-tabs(active-nav-item-class="text-uppercase tab-deliverys" content-class="mt-3" justified)
        b-tab(:title="`Por Entregar (N°${numberDelivery})`" :active="tabSelected === 'delivery'" @click="tabSelected='delivery'")
        b-tab(:title="`Entregados (N°${numberDelivered})`" :active="tabSelected === 'delivered'" @click="tabSelected='delivered'")
    b-container(fluid="lg")
      p(v-show="deliveries.length !== 0 " class="title-tab") {{ tabSelected === 'delivered' ? 'Envíos Entregados' : 'Lista de Viajes' }}
      p(v-if="Object.entries(deliveriesByCommune).length === 0 && !isLoading" class="text-center") {{ this.search ? 'No se encontraron resultados' : 'No hay entregas' }}
      p(
        v-for="[key, value] of Object.entries(deliveriesByCommune)"
        class="title-tab"
      ) {{ key }}

        b-card(
          v-for="delivery in value"
          :key="delivery.drivInCode"
          bg-variant="light"
          class="mt-4 shadow card-envios position-relative"
        )
          b-badge(
            :style="getBadgeStyle(delivery)"
            class="custom-badge"
          ) {{ tabSelected === 'delivered' ? 'Entregado' : delivery.type }}
          b-badge.tags(pill) {{ delivery.tagsName }}
          .material-icons.icon(class="icon-play" @click="detailtDelivery(delivery)") play_circle_filled
          p.text-title {{ `#${delivery.numberStore} ${delivery.nameStore}` }}
          b-row.row-between
            p.destinatario-text {{ delivery.finalCustomer }}
          p.text-address {{ delivery.address }}
          p.text-commune {{ delivery.commune }}
          p.text-observation.small-text Observaciones:  {{ delivery.observations ? delivery.observations : '-' }}
          p.text-tracking.small-text N° Seguimiento: {{ delivery.drivInCode }}
          div(v-if="isLoading" class="skeleton-container")
            b-skeleton(v-for="n in 3" :key="n" class="mt-2" type="input" animation="fade")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { io } from 'socket.io-client'
import settings from '../../../settings.js'

export default {
  name: 'TabDelivery',
  data () {
    return {
      deliveries: [],
      allDeliveries: [],
      isLoading: false,
      user: null,
      search: '',
      debounceTimer: null,
      tabSelected: '',
      websocketUrl: settings.baseApi.split('api')[0]
    }
  },
  computed: {
    ...mapGetters(['saveTabSelected']),
    deliveriedList () {
      return this.allDeliveries.filter(delivery => delivery.entregado)
    },
    deliveryList () {
      return this.allDeliveries.filter(delivery => !delivery.entregado)
    },
    filterDeliveries () {
      const filteredList = []
      if (this.tabSelected === 'delivered') {
        filteredList.push(...this.deliveriedList)
      } else {
        filteredList.push(...this.deliveryList)
      }
      return this.searchDeliveries(filteredList)
    },
    numberDelivery () {
      return this.deliveryList.length
    },
    numberDelivered () {
      return this.deliveriedList.length
    },
    deliveriesByCommune () {
      console.log('this.filterDeliveries', this.filterDeliveries)
      const deliveriesByCommune = {}
      for (const delivery of this.filterDeliveries) {
        const commune = delivery.commune.toUpperCase()
        deliveriesByCommune[commune]
          ? deliveriesByCommune[commune].push({ ...delivery })
          : deliveriesByCommune[commune] = [{ ...delivery }]
      }
      return deliveriesByCommune
    }
  },
  methods: {
    ...mapActions(['getMyDeliveries']),
    getBadgeStyle (delivery) {
      if (delivery.entregado) {
        return {
          backgroundColor: '#0C6089',
          color: 'white'
        }
      }
      return {
        backgroundColor: delivery.type === 'Retiro' ? '#A5DC93' : '#4D9A97',
        color: delivery.type === 'Retiro' ? '#4F4F4F' : 'white'
      }
    },
    debouncedSearch () {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.searchDeliveries()
      }, 500)
    },
    searchDeliveries (deliveries) {
      if (this.search.trim() === '') {
        return [...deliveries]
      }

      return deliveries.filter((delivery) => {
        const searchTerm = this.search.toLowerCase()
        return (
          (delivery.drivInCode && delivery.drivInCode.toLowerCase().includes(searchTerm)) ||
          (delivery.address && delivery.address.toLowerCase().includes(searchTerm)) ||
          (delivery.finalCustomer && delivery.finalCustomer.toLowerCase().includes(searchTerm)) ||
          (delivery.number && delivery.number.toLowerCase().includes(searchTerm)) ||
          (delivery.contactPhone && delivery.contactPhone.toLowerCase().includes(searchTerm))
        )
      })
    },
    async fetchDeliveries () {
      if (this.isLoading) return
      this.isLoading = true
      try {
        const response = await this.getMyDeliveries()
        if (response.status === 200) {
          this.allDeliveries = response.data
        }
      } catch (error) {
        console.error('Error al buscar', error)
      } finally {
        this.isLoading = false
      }
    },
    detailtDelivery (delivery) {
      this.$store.commit('SET_DELIVERY_SELECTED', delivery)
      this.$router.push(`/mis-envios/${delivery.id}`)
    },
    async initWebsockets () {
      console.log('conectando a websocket')
      this.socket = io(this.websocketUrl)

      this.socket.on('delivery:updated', (data) => {
        console.log('Envío actualizado', data || '')
        if (this.user.role === 'driver') {
          if (data.update.drivers?.includes(this.user.id)) {
            this.fetchDeliveries({ params: this.tabSelected || 'delivery' })
          }
        }
      })
    }
  },
  watch: {
    tabSelected (val) {
      this.$store.commit('SET_TAB_SELECTED', val)
    }
  },
  created () {
    const user = JSON.parse(localStorage.getItem('user')).user
    this.user = user
    this.tabSelected = this.saveTabSelected || 'delivery'
    this.fetchDeliveries()
    this.initWebsockets()
  },
  destroyed () {
    if (!this.socket) return
    this.socket.disconnect()
    console.log('conexión a websocket terminada')
  }
}
</script>
<style lang="scss" scoped>
#search-input-delivery::placeholder {
  color: #C3C3C3;
}
#search-input-delivery {
  border: none ;
}
.icon-search {
  color: #C3C3C3;
}
.icon-search-input{
  border: none;
  background-color: transparent;
}
.title-tab{
  color: #4F4F4F;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;

}
.bg-tab {
  background-color: #FFFFFF;
}
.tags {
  background-color: #F7B232;
  margin-block: auto;
  margin-top: 10px;
  font-size: 16px;
  padding: 9px;
  font-weight: 600;
  width: 100px;
  position: absolute;
  right: 15px;
  top: 40px;
}
.row-between {
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

</style>
