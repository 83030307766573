<template lang="pug">
  b-container#DeliveryComplete(fluid="lg")
    div.d-flex.align-items-center(@click="$router.go(-1)")
      span.material-icons.icon(class="icon-atras") forward
      p.title-detail-delivery Entregar Envío
    b-card(bg-variant="light" class="shadow card-envios position-relative")
      b-badge(:style="getBadgeStyle(delivery.type)" class="custom-badge") {{ delivery.type }}
      p.text-title {{ `#${delivery.numberStore} ${delivery.nameStore}` }}
      p.destinatario-text {{ delivery.finalCustomer }}
      p.text-address {{ delivery.address }}
      p.text-commune {{ delivery.commune }}
      p.text-observation.small-text Observaciones:  {{ delivery.observation ? delivery.observation : '-' }}
      p.text-tracking.small-text N° Seguimiento: {{ delivery.drivInCode }}
    b-card.mt-2(bg-variant="light" class="shadow position-relative")
      b-form(autocomplete="off" @submit.stop.prevent="onSubmit" :disabled="true")
        .box-form
          .material-icons.icon-input face
          b-form-group.pl-2(
            label-for="receiver-name"
            invalid-feedback="Este campo es requerido"
          )
            .input-title Persona que Recibe:
            b-form-input#receiver-name.input(
              v-model="form.receiverName"
              type="text"
              autocomplete="off"
              :disabled="loading"
              :state="$v.$dirty ? !$v.form.receiverName.$invalid : null"
              placeholder="Ingresa el nombre"
            )
        .box-form
          .material-icons.icon-input pin
          b-form-group.pl-2(
            label-for="receiver-rut"
            invalid-feedback="El rut es requerido"
          )
            .input-title Rut:
            b-form-input#receiver-rut.input(
              v-model="form.receiverRut"
              type="text"
              autocomplete="off"
              :disabled="loading"
              :state="$v.$dirty ? !$v.form.receiverRut.$invalid : null"
              placeholder="Ingresa el Rut"
            )
        .box-form
          .material-icons.icon-input change_circle
          b-form-group.pl-2(
            label-for="delivery-status"
            invalid-feedback="Debe seleccionar un estado"
          )
            .input-title Estado:
            b-form-select#delivery-status.input(
              v-model="form.status"
              :options="statusOptions"
              :disabled="loading"
              :state="$v.$dirty ? !$v.form.status.$invalid : null"
            )
        .box-form
          .material-icons.icon-input playlist_add_check
          b-form-group.pl-2(
            label-for="delivery-comment"
          )
            .input-title Comentario:
            b-form-textarea#comment.input(
              v-model="form.comment"
              type="text"
              autocomplete="false"
              placeholder="Ingresa aquí un comentario"
              rows="2"
              max-rows="3"
              maxlength="250"
              :disabled="loading"
            )
        .box-form
          .material-icons.icon-input image
          b-form-group.pl-2(
            label-for="delivery-images"
            invalid-feedback="Debe ingresar al menos 1 foto"
          )
            .input-title Imágenes (4 max.):
            .d-flex
              b-form-file#input-file.input.mr-2(
                v-model="photosToLoad"
                placeholder="Toca aquí para abrir la galería"
                browse-text="Buscar"
                accept="image/*"
                multiple
                :disabled="loading"
                :state="$v.$dirty ? !$v.form.images.$invalid : null"
              )
              b-button.d-flex.py-0(
                style="align-items: center;"
                @click="openCamera"
                variant="primary"
                size="sm"
                :disabled="loading"
              )
                .material-icons camera_alt
            b-form-file#open-camera.d-none(
              v-model="photosToLoad"
              accept="image/*"
              multiple
              :capture="true"
            )
        div.test1(style="display: flex; flex-wrap: wrap; margin-top: 10px; margin-bottom: 10px; gap: 10px;")
          div.test2(v-for="(image, index) in imageUrls")
            div(style="position: relative; width: 120px; height: 120px;")
              b-img(
                style="position: relative; width: 120px; height: 120px; margin-right: 10px; margin-bottom: 10px; border-radius: 10px; position: relative; border: 1px solid #E5E5E5; overflow: hidden; object-fit: contain;"
                v-if="image"
                :key="index"
                :src="image"
                class="photo"
                fluid
              )
              b-button(
                style="position: absolute; top: -10px; right: -10px; height: 20px; width: 20px; font-size: 5px; padding: 0; display: flex; justify-content: center; align-items: center;"
                @click="form.images.splice(index, 1)"
                variant="danger"
                size="sm"
                class="delete-photo"
                pill
              )
                .material-icons close
        b-button(type="submit" :disabled="loading" block :loading="loading") Completar
</template>

<script>
import Compressor from 'compressorjs'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import MakeToastMixin from '../mixins/MakeToastMixin'
import { io } from 'socket.io-client'
import settings from '../../../settings.js'

export default {
  mixins: [MakeToastMixin],
  props: {
    delivery: {
      type: Object
    }
  },
  data () {
    return {
      form: {
        status: '',
        receiverName: '',
        receiverRut: '',
        images: [],
        comment: ''
      },
      photosToLoad: null,
      statusOptions: [
        { text: 'Aprobado', value: 'approved' },
        { text: 'Rechazado', value: 'rejected' },
        { text: 'Parcial', value: 'partial' }
      ],
      loading: false,
      websocketUrl: settings.baseApi.split('api')[0]
    }
  },
  validations: {
    form: {
      receiverName: { required },
      receiverRut: { required },
      status: { required },
      images: { required }
    }
  },
  computed: {
    imageUrls () {
      console.log('images', this.form.images)
      if (!this.form.images) return []
      const urls = []
      if (this.form.images) {
        for (const img of this.form.images) {
          urls.push(URL.createObjectURL(img))
        }
      }
      return urls
    }
  },
  methods: {
    ...mapActions(['createDeliveryImage', 'completeDelivery']),
    getBadgeStyle (type) {
      return {
        backgroundColor: type === 'Retiro' ? '#A5DC93' : '#4D9A97',
        color: type === 'Retiro' ? '#4F4F4F' : 'white'
      }
    },
    openCamera () {
      document.getElementById('open-camera').click()
    },
    async onSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        const formData = new FormData()
        // console.log('Enviando datos para completar envío', this.form)
        const data = {
          comment: this.form.comment,
          receiverName: this.form.receiverName,
          receiverRut: this.form.receiverRut,
          status: this.form.status
        }
        formData.append('data', JSON.stringify(data))
        const now = new Date()

        for (const img of this.form.images) {
          await new Promise((resolve, reject) =>
            new Compressor(img, {
              quality: 0.4,
              convertSize: 5000,
              convertTypes: ['image/png', 'image/webp', 'image/jpg', 'image/jpeg'],
              maxHeight: 720,
              maxWidth: 720,
              success (result) {
                console.log('comprimiendo imagen...')
                const lastDot = result.name.lastIndexOf('.')
                const extension = result.name.substring(lastDot + 1)
                formData.append('files', result, `IMG_${result.name.split(extension)[0]}_${now.getTime()}.${extension}`)
                resolve(result)
              },
              error (err) {
                console.log(err.message)
                reject(err)
              }
            })
          ).then(response => {
            console.log('imagen comprimida')
          })
        }
        const response = await this.completeDelivery({ data: formData, deliveryId: this.delivery.id })
        if (response.status === 200) {
          this.makeSuccessToast('El envío se completó con éxito!')
          if (this.socket) {
            this.socket.emit('delivery:updated', { update: { drivers: [this.delivery.driverId], stores: [this.delivery.customerName] } })
          }
          this.$router.replace({ path: '/mis-envios' })
          window.history.replaceState(null, '', '/mis-envios')
          this.$emit('done')
        } else if (response.status >= 400 && response.status < 500) {
          this.makeDangerToast(response.data.message || 'No se pudo completar el envío')
        }
        this.loading = false
      } else {
        this.makeDangerToast('Por favor, completa todos los campos requeridos.')
      }
    },
    resetForm () {
      this.form.receiverName = ''
      this.form.receiverRut = ''
      this.form.status = ''
      this.form.comment = ''
      this.form.images = []
    },
    async initWebsockets () {
      console.log('conectando a websocket')
      this.socket = io(this.websocketUrl)

      this.socket.on('delivery:updated', (data) => {
        console.log('Envío actualizado', data || '')
      })
    }
  },
  async created () {
    this.initWebsockets()
  },
  watch: {
    'form.images': {
      handler (value) {
        if (this.form.images.length >= 5) {
          this.form.images.pop()
        }
      },
      deep: true
    },
    photosToLoad (value) {
      if (value) {
        if (!this.photosToLoad) return
        for (const photo of value) {
          this.form.images.push(photo)
        }
        this.photosToLoad = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-detail-delivery{
  color: #4F4F4F;
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  margin-top: 19px;
}
.icon-atras{
  transform: rotate(180deg);
  color:#264848;
  width: 24px;
  height: 24px;
  margin-right: 5px;

}
</style>
